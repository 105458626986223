(function () {
    let $ = jQuery;
    if (document.getElementById('scroll-more') != null) {
        initScrollIcon();
        initScrollIconListener();
    }

    function initScrollIcon() {
        lottie.loadAnimation({
            container: document.getElementById('scroll-more'),
            renderer: 'svg',
            loop: true, 
            autoplay: true,
            path: window.Theme.theme_dir + '/assets/js/scrolling-icon.json'
        });
    }

    function initScrollIconListener() {
        var isHidden = false;
        if ($("#site-footer:in-viewport").length > 0) {
            $('#scroll-more').hide();
            isHidden = true;
        }
        $(window).on('scroll', throttle(function () {
            if ($("#site-footer:in-viewport").length > 0) {
                if (!isHidden) {
                    $('#scroll-more').fadeOut();
                    isHidden = true;
                }
            } else {
                if (isHidden) {
                    $('#scroll-more').fadeIn().show();
                    isHidden = false;
                }
            }
        }, 300));
    }


    // Returns a function, that, when invoked, will only be triggered at most once
    // during a given window of time. Normally, the throttled function will run
    // as much as it can, without ever going more than once per `wait` duration;
    // but if you'd like to disable the execution on the leading edge, pass
    // `{leading: false}`. To disable execution on the trailing edge, ditto.
    function throttle(func, wait, options) {
        var context, args, result;
        var timeout = null;
        var previous = 0;
        if (!options) options = {};
        var later = function () {
            previous = options.leading === false ? 0 : Date.now();
            timeout = null;
            result = func.apply(context, args);
            if (!timeout) context = args = null; 
        };
        return function () {
            var now = Date.now();
            if (!previous && options.leading === false) previous = now;
            var remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0 || remaining > wait) {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                previous = now;
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            } else if (!timeout && options.trailing !== false) {
                timeout = setTimeout(later, remaining);
            }
            return result;
        };
    };
})(jQuery);
